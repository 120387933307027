<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    automationToolKey() {
      return this.authStore.api_keys.automation_tool;
    },
  },
  methods: {
    generateKey(type) {
      if (this.automationToolKey) {
        this.isLoading = true;
        this.$buefy.dialog.confirm({
          title: 'Cette action comporte des risques',
          message: 'En regénérant votre clé, cela fera disfonctionner vos connecteurs Zapier ou Make en cours.',
          type: 'is-danger',
          focusOn: 'cancel',
          onCancel: () => (this.isLoading = false),
          onConfirm: () => this.$store.dispatch('auth/generateKey', type)
            .finally(() => (this.isLoading = false)),
        });
      } else {
        this.isLoading = true;
        this.$store.dispatch('auth/generateKey', type)
          .finally(() => (this.isLoading = false));
      }
    },
    deleteKey(type) {
      this.isLoading = true;
      this.$buefy.dialog.prompt({
        title: 'Attention, cette action est définitive !',
        message: `
          <p>
            En regénérant votre clé, cela fera disfonctionner vos connecteurs Zapier ou Make en cours.
          </p>
          <p class="mt-3 has-text-weight-bold">
            Confirmez l'action en renseignant votre mot de passe.
          </p>
        `,
        type: 'is-danger',
        inputAttrs: { type: 'password', placeholder: 'Mot de passe' },
        onCancel: () => (this.isLoading = false),
        onConfirm: (password) => this.$store.dispatch('auth/deleteKey', { type, password })
          .finally(() => (this.isLoading = false)),
      });
    },
  },
};
</script>

<template>
  <section>
    <AppFeatureWrapper>
      <template #title>
        Zapier et Make <AppFeatureBadge class="ml-3" position="is-right" />
      </template>
      <template #content>
        <p>
          Vous voulez connecter Teachizy avec d'autres applications ?
        </p>
        <p>
          <strong>Connectez votre compte Teachizy à
            Zapier ou Make</strong>
          en générant une clé d'authentification (aussi appelée clé d'API).
        </p>
        <p>
          Attention ! Pour accéder à l'application Teachizy sur Zapier ou Make,
          <b>vous devez impérativement passer par les liens privés ;</b>
          l'application n'étant pas disponible publiquement sur les stores.
        </p>
      </template>
      <template #form>
        <div class="box">
          <div class="notification is-light content">
            <h4>Cette clé est secrète, ne la partagez pas !</h4>
            <p>
              Vous devez utiliser cette clé côté Zapier ou Make pour authentifier
              votre compte Teachizy, afin de recevoir et envoyer uniquement les données vous concernant.
            </p>
          </div>
          <go-pro-wrapper>
            <b-field v-if="automationToolKey" label="Votre clé d'API">
              <b-input :value="automationToolKey" readonly expanded />
              <p class="control">
                <b-button
                  type="is-primary"
                  v-clipboard:copy="automationToolKey"
                  v-clipboard:success="() => this.$copySuccess()"
                  v-clipboard:error="() => this.$copyError()"
                >
                  Copier
                </b-button>
              </p>
            </b-field>
            <b-field class="buttons">
              <b-button
                type="is-primary"
                :loading="isLoading"
                @click="generateKey('automation_tool')"
              >
                {{ automationToolKey ? 'Re-générer' : 'Générer' }} ma clé d'API
              </b-button>
              <b-button
                v-if="automationToolKey"
                class="ml-3"
                type="is-text has-text-danger"
                :loading="isLoading"
                @click="deleteKey('automation_tool')"
              >
                Supprimer la clé
              </b-button>
            </b-field>
            <div class="content w-full">
              <h4>Voici les liens privés vers les connecteurs</h4>
              <p class="has-text-clipped">
                <strong>Zapier : </strong>
                <a
                  href="https://zapier.com/developer/public-invite/216186/2927a3f99caf08a255d56ad2b3deb51e/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://zapier.com/developer/public-invite/216186/2927a3f99caf08a255d56ad2b3deb51e/
                </a>
              </p>
              <p class="has-text-clipped">
                <strong>Make : </strong>
                <a
                  href="https://www.make.com/en/hq/app-invitation/a686c72ac7a30f0e5822dde0f54d84f9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.make.com/en/hq/app-invitation/a686c72ac7a30f0e5822dde0f54d84f9
                </a>
              </p>
            </div>
          </go-pro-wrapper>
        </div>
      </template>
    </AppFeatureWrapper>
  </section>
</template>
